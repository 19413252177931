import React, { useEffect, useMemo, useState } from "react"
import PageRoot from "../components/PageRoot"
import { Button, Col, Empty, Row } from "antd"
import { graphql, Link } from "gatsby"
import EventureCarousel from "../components/EventureCarousel"
import {
  getCategoryDataSource,
  getHomePageData,
  getLatestItems,
  getMostViewedItemList,
  renderCarouselItem,
  translatePath
} from "../utils"
import useFavoriteItems from "../hooks/useFavoriteItems"
import { useAuth0 } from "@auth0/auth0-react"
import { useTranslation } from "react-i18next"
import { ON_DEMAND } from "../enums/PricePolicies"
import GenericHeader from "../components/GenericHeader"

import wallpaper from "../../static/assets/images/hexagon-bg.jpg"

const HomePage = ({ data, pageContext }) => {
  const { t } = useTranslation()

  const {
    videos,
    courses,
    categories,
    authors,
    channels,
    mostViewedVideos,
    featureCategories
  } = data.eventure

  const onDemandCourses = courses.filter(
    course => course.price_policy === ON_DEMAND
  )
  const otherCourses = courses.filter(
    course => course.price_policy !== ON_DEMAND
  )

  const allItemList = [...videos, ...courses]

  const [featuredItemList, setFeaturedItemList] = useState([])
  const [latestList, setLatestList] = useState([])
  const [suggestedItemList, setSuggestedItemList] = useState([])
  const [mostViewedItemList, setMostViewedItemList] = useState([])
  const favoriteItemList = useFavoriteItems(allItemList)
  const { isAuthenticated } = useAuth0()

  const _getMostViewedItemList = async () => {
    try {
      const itemList = await getMostViewedItemList(mostViewedVideos)

      if (!itemList) {
        setMostViewedItemList(null)
        return
      }

      const result = []
      itemList.map(item => {
        const video = allItemList.find(({ id }) => id === item.id)
        if (video) {
          result.push(video)
        }
      })

      setMostViewedItemList(result)
    } catch (error) {
      console.log({ error })
    }
  }

  useEffect(() => {
    const { videos, courses, global_settings } = data.eventure

    const homePageSettings = JSON.parse(
      global_settings.find(({ key }) => key === "HOME_PAGE_LAYOUT")?.value ??
      "{}"
    )

    const { featuredItemList, suggestedItemList } = getHomePageData(
      homePageSettings,
      videos,
      courses
    )

    _getMostViewedItemList()

    setFeaturedItemList(featuredItemList)
    setLatestList(getLatestItems(videos, courses))
    setSuggestedItemList(suggestedItemList)
  }, [])

  const heroSlider = useMemo(() => {
    return (
      <GenericHeader
        data={{
          title: "The First Multifunction Learning Apparate.",
          subtitle: "Powered by Blockchain, UBER Extreme AI and NFT SUPERDRIVE",
          wallpaper: wallpaper,
          home: true,
          actions: [
            <Link to={"/help"}>
              <Button size={"large"} type="primary">
                {t("button:contactUs")}
              </Button>
            </Link>
          ]
        }}
      />
    )
  }, [featuredItemList])

  const latestItemRow = useMemo(() => {
    return (
      <EventureCarousel
        title={t("label:latest")}
        dataSource={latestList}
        renderItem={renderCarouselItem}
      />
    )
  }, [latestList])

  const favoritesRow = useMemo(() => {
    return (
      <EventureCarousel
        title={t("label:myList")}
        dataSource={favoriteItemList}
        loading={favoriteItemList === null}
        renderItem={renderCarouselItem}
        emptyItem={
          <Empty description={"Non hai ancora aggiunto alcun video"} />
        }
      />
    )
  }, [favoriteItemList])

  const mostViewedRow = useMemo(() => {
    return (
      <EventureCarousel
        title={t("label:mostFollowed")}
        dataSource={mostViewedItemList}
        loading={mostViewedItemList === null}
        renderItem={(item, index) => {
          return renderCarouselItem(item, index, true)
        }}
      />
    )
  }, [mostViewedItemList])

  const channelsRow = useMemo(() => {
    return (
      <EventureCarousel
        colSettings={{ xs: 12, sm: 12, md: 8, lg: 6, xl: 5, xxl: 4 }}
        url={translatePath("/learning-areas/all")}
        title={t("menu:learningAreas")}
        dataSource={channels}
        renderItem={renderCarouselItem}
      />
    )
  }, [channels])

  const suggestionsRow = useMemo(() => {
    return (
      <EventureCarousel
        title={t("label:suggested")}
        dataSource={suggestedItemList}
        renderItem={renderCarouselItem}
      />
    )
  }, [suggestedItemList])

  const categoriesRow = useMemo(() => {
    return (
      <EventureCarousel
        colSettings={{ xs: 14, sm: 14, md: 8, lg: 6, xl: 5, xxl: 4 }}
        url={translatePath("/category/all")}
        title={t("label:categories")}
        dataSource={categories}
        renderItem={renderCarouselItem}
      />
    )
  }, [categories])

  const coursesRow = useMemo(() => {
    return (
      <EventureCarousel
        title={t("label:allSeries")}
        dataSource={otherCourses}
        renderItem={renderCarouselItem}
      />
    )
  }, [otherCourses])

  const authorsRow = useMemo(() => {
    return (
      <EventureCarousel
        colSettings={{ xs: 10, sm: 10, md: 8, lg: 6, xl: 4, xxl: 3 }}
        title={t("label:authors")}
        url={translatePath("/author/all")}
        dataSource={authors}
        renderItem={renderCarouselItem}
      />
    )
  }, [authors])

  const onDemandCoursesRow = useMemo(() => {
    return (
      <EventureCarousel
        isVertical={true}
        title={t("label:onDemandCourses")}
        url={translatePath("/on-demand")}
        dataSource={onDemandCourses}
        renderItem={renderCarouselItem}
      />
    )
  }, [onDemandCourses])

  const categoriesWithContentRow = useMemo(() => {
    return featureCategories.map(category => (
      <Row key={category.id}>
        <EventureCarousel
          title={category.name}
          dataSource={getCategoryDataSource(category)}
          renderItem={renderCarouselItem}
        />
      </Row>
    ))
  }, [featureCategories])

  return (
    <PageRoot>
      {heroSlider}
      <Row>
        <Col span={24}>{latestItemRow}</Col>
        {/*{isAuthenticated && <Col span={24}>{favoritesRow}</Col>}*/}
        <Col span={24}>{mostViewedRow}</Col>
        <Col span={24}>{channelsRow}</Col>
        {/*<Col span={24}>{suggestionsRow}</Col>*/}
        <Col span={24}>{categoriesRow}</Col>
        <Col span={24}>{coursesRow}</Col>
        <Col span={24}>{authorsRow}</Col>
        <Col span={24}>{onDemandCoursesRow}</Col>
        <Col span={24}>{categoriesWithContentRow}</Col>
      </Row>
    </PageRoot>
  )
}

export default HomePage

export const query = graphql`
  query homePageQuery($lastMonth: eventure_timestamptz) {
    eventure {
      videos(
        order_by: { created_at: desc }
        where: {
          _and: [
            {
              _or: [
                { state: { _eq: PUBLISHED } }
                { state: { _eq: COMING_SOON } }
              ]
            }
            {
              _or: [
                {
                  course_rel: { course: { price_policy: { _neq: ON_DEMAND } } }
                }
              ]
            }
          ]
        }
      ) {
        ...PreviewCardVideo
        header_image {
          ...SvgImage
        }
        wallpaper {
          ...Wallpaper
        }
        author_rels {
          author {
            id
            firstname
            lastname
            title
          }
        }
        course_rel {
          course {
            ...PreviewCardCourse
          }
        }
      }
      courses(
        order_by: { created_at: desc }
        where: {
          _or: [{ state: { _eq: PUBLISHED } }, { state: { _eq: COMING_SOON } }]
        }
      ) {
        ...PreviewCardCourse
        header_image {
          ...SvgImage
        }
        wallpaper {
          ...Wallpaper
        }
      }
      categories(where: { _not: { parent: {} } }, order_by: { name: asc }) {
        ...PreviewCardCategory
      }
      channels {
        ...PreviewCardChannel
      }
      global_settings {
        key
        value
      }
      authors {
        ...PreviewCardAuthor
      }
      mostViewedVideos: videos(
        order_by: {
          played_ranges_aggregate: { sum: { duration: desc_nulls_last } }
        }
        limit: 10
      ) {
        id
        course_rel {
          course_id
        }
        played_ranges_aggregate(where: { created_at: { _gte: $lastMonth } }) {
          aggregate {
            sum {
              duration
            }
          }
        }
      }
      featureCategories: categories(
        where: {
          id: {
            _in: [
              "56620c18-5a7c-4d15-b631-627177e22ed8"
              "e5fabc0f-6fcf-4265-ba4e-5fcce8695668"
              "e5fabc0f-6fcf-4265-ba4e-5fcce8695668"
              "274c0ecd-97c7-47dd-bc9e-6c92204d32a7"
              "1e7dbf9b-bcdc-4963-a159-5940d1a7f46b"
            ]
          }
        }
      ) {
        ...CategoryWithAllContent
      }
    }
  }
`
